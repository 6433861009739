@import "frappe/public/css/octicons/octicons.css";
.font-size-xs {
  font-size: 0.75rem;
}

.font-size-sm {
  font-size: 0.875rem;
}

.font-size-base {
  font-size: 1rem;
}

.font-size-lg {
  font-size: 1.125rem;
}

.font-size-xl {
  font-size: 1.25rem;
}

.font-size-2xl {
  font-size: 1.5rem;
}

.font-size-3xl {
  font-size: 1.875rem;
}

.font-size-4xl {
  font-size: 2.25rem;
}

.font-size-5xl {
  font-size: 3rem;
}

.font-size-6xl {
  font-size: 4rem;
}

:root,
[data-theme=light] {
  --brand-color: #0089FF;
  --pink-900: #5B1E34;
  --pink-800: #702440;
  --pink-700: #97264F;
  --pink-600: #D2326D;
  --pink-500: #F2508B;
  --pink-400: #F46B9E;
  --pink-300: #F584AE;
  --pink-200: #FAB9D1;
  --pink-100: #FCDCE8;
  --pink-50: #FEEDF3;
  --blue-900: #1A4469;
  --blue-800: #154875;
  --blue-700: #1366AE;
  --blue-600: #1673C5;
  --blue-500: #2490EF;
  --blue-400: #50A6F2;
  --blue-300: #7CBCF5;
  --blue-200: #A7D3F9;
  --blue-100: #D3E9FC;
  --blue-50: #F0F8FE;
  --cyan-900: #006464;
  --cyan-800: #007272;
  --cyan-700: #008b8b;
  --cyan-600: #02c5c5;
  --cyan-500: #00ffff;
  --cyan-400: #2ef8f8;
  --cyan-300: #6efcfc;
  --cyan-200: #a0f8f8;
  --cyan-100: #c7fcfc;
  --cyan-50: #dafafa;
  --green-900: #2D401D;
  --green-800: #44622A;
  --green-700: #518B21;
  --green-600: #68AB30;
  --green-500: #8CCF54;
  --green-400: #A5D97A;
  --green-300: #BCE39B;
  --green-200: #D2ECBD;
  --green-100: #E9F6DE;
  --green-50: #F4FAEE;
  --dark-green-900: #1F3E2B;
  --dark-green-800: #29593B;
  --dark-green-700: #286840;
  --dark-green-600: #2E844E;
  --dark-green-500: #2F9D58;
  --dark-green-400: #59B179;
  --dark-green-300: #82C49B;
  --dark-green-200: #ACD8BC;
  --dark-green-100: #DFEEE5;
  --dark-green-50: #EAF5EE;
  --gray-900: #1F272E;
  --gray-800: #333C44;
  --gray-700: #505A62;
  --gray-600: #687178;
  --gray-500: #98A1A9;
  --gray-400: #C0C6CC;
  --gray-300: #DCE0E3;
  --gray-200: #EBEEF0;
  --gray-100: #F4F5F6;
  --gray-50: #F9FAFA;
  --red-900: #631D1D;
  --red-800: #802121;
  --red-700: #9B2C2C;
  --red-600: #C53030;
  --red-500: #E24C4C;
  --red-400: #EC645E;
  --red-300: #FCA4A4;
  --red-200: #FCC8C8;
  --red-100: #FFECEC;
  --red-50: #FFF5F5;
  --yellow-900: #5F370E;
  --yellow-800: #744210;
  --yellow-700: #975A16;
  --yellow-600: #B7791F;
  --yellow-500: #D69E2E;
  --yellow-400: #ECB64B;
  --yellow-300: #F6C35E;
  --yellow-200: #FAD489;
  --yellow-100: #FEE9BF;
  --yellow-50: #FEF4E2;
  --orange-900: #653019;
  --orange-800: #7B3A1E;
  --orange-700: #9C4621;
  --orange-600: #CB5A2A;
  --orange-500: #F8814F;
  --orange-400: #F9966C;
  --orange-300: #FDAE8C;
  --orange-200: #FECDB8;
  --orange-100: #FFEAE1;
  --orange-50: #FFF5F0;
  --purple-900: #343360;
  --purple-800: #45438F;
  --purple-700: #4946BF;
  --purple-600: #6461D6;
  --purple-500: #8684FF;
  --purple-400: #9897F7;
  --purple-300: #B9B8FA;
  --purple-200: #DAD9F7;
  --purple-100: #EAEAFB;
  --purple-50: #F2F2FD;
  --padding-xs: 5px;
  --padding-sm: 10px;
  --padding-md: 15px;
  --padding-lg: 20px;
  --padding-xl: 30px;
  --padding-2xl: 40px;
  --margin-xs: 5px;
  --margin-sm: 8px;
  --margin-md: 15px;
  --margin-lg: 20px;
  --margin-xl: 30px;
  --margin-2xl: 40px;
  --font-stack: "Inter", "-apple-system", "BlinkMacSystemFont","Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell","Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
  --text-bold: 500;
  --shadow-xs: rgba(0, 0, 0, 0.05) 0px 0.5px 0px 0px, rgba(0, 0, 0, 0.08) 0px 0px 0px 1px, rgba(0, 0, 0, 0.05) 0px 2px 4px 0px;
  --shadow-sm: 0px 1px 2px rgba(25, 39, 52, 0.05), 0px 0px 4px rgba(25, 39, 52, 0.1);
  --shadow-base: 0px 4px 8px rgba(25, 39, 52, 0.06), 0px 0px 4px rgba(25, 39, 52, 0.12);
  --shadow-md: 0px 8px 14px rgba(25, 39, 52, 0.08), 0px 2px 6px rgba(25, 39, 52, 0.04);
  --shadow-lg: 0px 18px 22px rgba(25, 39, 52, 0.1), 0px 1px 10px rgba(0, 0, 0, 0.06), 0px 0.5px 5px rgba(25, 39, 52, 0.04);
  --drop-shadow: 0px 0.5px 0px rgba(0, 0, 0, 0.05), 0px 0px 0px rgba(0, 0, 0, 0), 0px 2px 4px rgba(0, 0, 0, 0.05);
  --modal-shadow: var(--shadow-md);
  --card-shadow: var(--shadow-sm);
  --btn-shadow: var(--shadow-xs);
  --text-muted: var(--gray-700);
  --text-light: var(--gray-800);
  --text-color: var(--gray-900);
  --heading-color: var(--gray-900);
  --icon-fill: transparent;
  --icon-fill-bg: var(--fg-color);
  --icon-stroke: var(--gray-900);
  --bg-blue: var(--blue-100);
  --bg-light-blue: var(--blue-50);
  --bg-dark-blue: var(--blue-300);
  --bg-green: var(--dark-green-50);
  --bg-yellow: var(--yellow-50);
  --bg-orange: var(--orange-50);
  --bg-red: var(--red-50);
  --bg-gray: var(--gray-200);
  --bg-grey: var(--gray-200);
  --bg-light-gray: var(--gray-100);
  --bg-dark-gray: var(--gray-400);
  --bg-darkgrey: var(--gray-400);
  --bg-purple: var(--purple-100);
  --bg-pink: var(--pink-50);
  --bg-cyan: var(--cyan-50);
  --text-xs: 11px;
  --text-sm: 12px;
  --text-md: 13px;
  --text-base: 14px;
  --text-lg: 16px;
  --text-xl: 18px;
  --text-2xl: 20px;
  --text-3xl: 22px;
  --text-on-blue: var(--blue-700);
  --text-on-light-blue: var(--blue-600);
  --text-on-dark-blue: var(--blue-800);
  --text-on-green: var(--dark-green-700);
  --text-on-yellow: var(--yellow-700);
  --text-on-orange: var(--orange-700);
  --text-on-red: var(--red-600);
  --text-on-gray: var(--gray-700);
  --text-on-grey: var(--gray-700);
  --text-on-darkgrey: var(--gray-800);
  --text-on-dark-gray: var(--gray-800);
  --text-on-light-gray: var(--gray-800);
  --text-on-purple: var(--purple-700);
  --text-on-pink: var(--pink-700);
  --text-on-cyan: var(--cyan-800);
  --alert-text-danger: var(--red-600);
  --alert-text-warning: var(--yellow-700);
  --alert-text-info: var(--blue-700);
  --alert-text-success: var(--green-700);
  --alert-bg-danger: var(--red-50);
  --alert-bg-warning: var(--yellow-50);
  --alert-bg-info: var(--blue-50);
  --alert-bg-success: var(--green-50);
  --bg-color: var(--gray-50);
  --fg-color: white;
  --navbar-bg: white;
  --fg-hover-color: var(--gray-100);
  --card-bg: var(--fg-color);
  --disabled-text-color: var(--gray-700);
  --disabled-control-bg: var(--gray-50);
  --control-bg: var(--gray-100);
  --control-bg-on-gray: var(--gray-200);
  --awesomebar-focus-bg: var(--fg-color);
  --modal-bg: white;
  --toast-bg: var(--modal-bg);
  --popover-bg: white;
  --awesomplete-hover-bg: var(--control-bg);
  --btn-default-bg: var(--gray-100);
  --btn-default-hover-bg: var(--gray-300);
  --sidebar-select-color: var(--gray-200);
  --scrollbar-thumb-color: var(--gray-400);
  --scrollbar-track-color: var(--gray-200);
  --shadow-inset: inset 0px -1px 0px var(--gray-300);
  --border-color: var(--gray-100);
  --dark-border-color: var(--gray-300);
  --table-border-color: var(--gray-200);
  --highlight-color: var(--gray-50);
  --yellow-highlight-color: var(--yellow-50);
  --btn-group-border-color: var(--gray-300);
  --highlight-shadow: 1px 1px 10px var(--blue-50), 0px 0px 4px var(--blue-600);
  --code-block-bg: var(--gray-900);
  --code-block-text: var(--gray-400);
  --border-radius-sm: 4px;
  --border-radius: 6px;
  --border-radius-md: 8px;
  --border-radius-lg: 12px;
  --border-radius-full: 999px;
  --primary-color: #2490EF;
  --btn-height: 30px;
  --input-height: calc(1.5em + 0.75rem + 2px);
  --input-disabled-bg: var(--gray-200);
  --checkbox-right-margin: var(--margin-xs);
  --checkbox-size: 14px;
  --checkbox-focus-shadow: 0 0 0 2px var(--gray-300);
  --checkbox-gradient: linear-gradient(180deg, #4AC3F8 -124.51%, var(--primary) 100%);
  --diff-added: var(--green-100);
  --diff-removed: var(--red-100);
  --right-arrow-svg: url("data: image/svg+xml;utf8, <svg width='6' height='8' viewBox='0 0 6 8' fill='none' xmlns='http://www.w3.org/2000/svg'><path d='M1.25 7.5L4.75 4L1.25 0.5' stroke='%231F272E' stroke-linecap='round' stroke-linejoin='round'/></svg>");
  --left-arrow-svg: url("data: image/svg+xml;utf8, <svg width='6' height='8' viewBox='0 0 6 8' fill='none' xmlns='http://www.w3.org/2000/svg'><path d='M7.5 9.5L4 6l3.5-3.5' stroke='%231F272E' stroke-linecap='round' stroke-linejoin='round'></path></svg>");
}

:root {
  --gray-50: #F9FAFA;
  --gray-100: #F4F5F6;
  --gray-200: #EEF0F2;
  --gray-300: #E2E6E9;
  --gray-400: #C8CFD5;
  --gray-500: #A6B1B9;
  --gray-600: #74808B;
  --gray-700: #4C5A67;
  --gray-800: #313B44;
  --gray-900: #192734;
  --black: #000;
  --primary: #2490ef;
  --primary-color: #2490ef;
  --primary-light: #ecf5fe;
  --light: #F9FAFA;
  --font-size-xs: 0.75rem;
  --font-size-sm: 0.875rem;
  --font-size-base: 1rem;
  --font-size-lg: 1.125rem;
  --font-size-xl: 1.25rem;
  --font-size-2xl: 1.5rem;
  --font-size-3xl: 1.875rem;
  --font-size-4xl: 2.5rem;
  --font-size-5xl: 3rem;
  --font-size-6xl: 4rem;
  --card-border-radius: 0.75rem;
}

@media (max-width: 991.98px) {
  .small-image {
    max-width: 200px;
    align-self: center !important;
  }
}